import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Head from "../components/Head";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Service from "../components/Service";
import Instagram from "../components/InstagramEmbeded";
import Recruit from "../components/Recruit";
import News from "../components/News";
import Menu from "../components/Menu";
import SecondView from "../components/SecondView";
import Map from "../components/Map";
import OfficeShibuyaSliderImage from "../components/OfficeShibuyaSliderImage";
import AOS from "aos";

const OfficeShibuya = () => {
  const data = useStaticQuery(
    graphql`
      query {
        view: file(relativePath: { eq: "secondView/second-view-3.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        sp_view: file(relativePath: { eq: "secondView/second-view-3-sp.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    `
  );
  if (typeof document !== `undefined`) {
    console.log("find document");
    AOS.init({ duration: 800, easing: "ease-in-sine", delay: 300 });
  }
  const image = getImage(data.view);
  const spImage = getImage(data.sp_view);

  return (
    <>
      <Head
        title="フォルカフェ渋谷店 | forucafe"
        description="フォルカフェ渋谷店です。forucafe渋谷店は、渋谷スクランブルスクエアの39F
シェアオフィス内にありメンバーさんとそのゲストの方のみご利用いただけます。
オフィスでゲストとランチ、頭をフル回転させた後に甘いドリンク
夕方小腹が空いたらサンドウィッチ など渋谷上空からの絶景とともにお楽しみいただけます。"
        url="https://forustyle-985bb.web.app/office-shibuya"
        imagePath="https://forustyle-985bb.web.app/ogp-shibuya.jpg"
      />
      <Header title="forucafe 渋谷店" />
      <OfficeShibuyaSliderImage />
      <div data-aos="fade-in">
        <SecondView
          data={image}
          spData={spImage}
          text={`forucafe渋谷店は、渋谷スクランブルスクエアの39F
シェアオフィス内にあり
メンバーさんとそのゲストの方のみご利用いただけます。
オフィスでゲストとランチ、頭をフル回転させた後に甘いドリンク
夕方小腹が空いたらサンドウィッチ など
渋谷上空からの絶景とともにお楽しみいただけます。`}
          textTb={`forucafe渋谷店は、渋谷スクランブルスクエアの39F
シェアオフィス内にあり
メンバーさんとそのゲストの方のみご利用いただけます。
オフィスでゲストとランチ、頭をフル回転させた後に甘いドリンク
夕方小腹が空いたらサンドウィッチ など
渋谷上空からの絶景とともにお楽しみいただけます。`}
          textSp={`forucafe渋谷店は、
渋谷スクランブルスクエアの39F
シェアオフィス内にあり
メンバーさんとそのゲストの方のみ
ご利用いただけます。
オフィスでゲストとランチ、
頭をフル回転させた後に甘いドリンク
夕方小腹が空いたらサンドウィッチ など
渋谷上空からの絶景とともに
お楽しみいただけます。`}
        />
      </div>
      <div data-aos="fade-in">
        <Menu shop="渋谷店" isDetail={false} />
      </div>
      <div data-aos="fade-in">
        <Map
          title={`forucafe渋谷店`}
          textUnderTheTitle={`※WeWorkメンバーのみご利用いただけます。`}
          post={`〒1506139 東京都渋谷区渋谷2-24-12
WeWork渋谷スクランブルスクエア39F
39F,2-24-12,Shibuya,Shibuya-ku,Tokyo`}
          nearestStation={0}
          phoneNumber={0}
          isWifi={0}
          isPowerSupply={0}
          time={`9:00~19:00`}
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3241.7548675579865!2d139.7022406!3d35.6584107!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188bb2d5a629e7%3A0x56ef74a605ea7ff!2zV2VXb3JrIOa4i-iwt-OCueOCr-ODqeODs-ODluODq-OCueOCr-OCqOOCog!5e0!3m2!1sja!2sjp!4v1645793580356!5m2!1sja!2sjp"
        />
      </div>
      <div data-aos="fade-in">
        <News shop="渋谷店" />
      </div>
      <div data-aos="fade-in">
        <Service />
      </div>
      <div data-aos="fade-in">
        <Recruit />
      </div>
      <div data-aos="fade-in">
        <Footer />
      </div>
    </>
  );
};

export default OfficeShibuya;
