import React from "react";
import Slider from "react-slick";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import Box from "@mui/material/Box";

const FullImage = ({ children }) => {
  return <Box sx={{ width: "100vw", height: "100vh" }}>{children}</Box>;
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <Box
      sx={{
        height: "100vh",
        position: "absolute",
        display: "flex",
        alignItems: "center",
        left: 5,
      }}
    >
      <div
        style={{ position: "relative", zIndex: 10, display: "block" }}
        onClick={onClick}
      >
        <StaticImage
          alt="img"
          src="../images/prevArrow.png"
          placeholder="blurred"
          quality="100"
          style={{ width: "21px" }}
        />
      </div>
    </Box>
  );
};

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <Box
      sx={{
        height: "100vh",
        position: "absolute",
        display: "flex",
        alignItems: "center",
        top: 0,
        right: 5,
      }}
    >
      <div
        style={{ position: "relative", right: 0, zIndex: 10, display: "block" }}
        onClick={onClick}
      >
        <StaticImage
          alt="img"
          src="../images/nextArrow.png"
          placeholder="blurred"
          quality="100"
          style={{ width: "21px" }}
        />
      </div>
    </Box>
  );
};

const OfficeShibuyaSliderImage = (props) => {
  const data = useStaticQuery(
    graphql`
      query {
        first_view_1: file(
          relativePath: { eq: "officeShibuya/first-view-1.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        first_view_2: file(
          relativePath: { eq: "officeShibuya/first-view-2.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        first_view_3: file(
          relativePath: { eq: "officeShibuya/first-view-3.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        first_view_sp_1: file(relativePath: { eq: "sp/spshibuya1.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        first_view_sp_2: file(relativePath: { eq: "sp/spshibuya2.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        first_view_sp_3: file(relativePath: { eq: "sp/spshibuya3.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    `
  );

  const imageArray = [getImage(data.first_view_1), getImage(data.first_view_2)];
  const imageSpArray = [
    getImage(data.first_view_sp_1),
    getImage(data.first_view_sp_2),
    getImage(data.first_view_sp_3),
  ];

  // const image1 = getImage(data.first_view_sp_1)
  // const image2 = getImage(data.first_view_sp_2)
  // const image3 = getImage(data.first_view_sp_3)

  // const image1 = data?.first_view_1?.childImageSharp?.gatsbyImageData
  // const image2 = data?.first_view_2?.childImageSharp?.gatsbyImageData
  // const image3 = data?.first_view_3?.childImageSharp?.gatsbyImageData
  // const imageArray = [image2, image1, image3]

  // const image1a = data?.first_view_sp_1?.childImageSharp?.gatsbyImageData
  // const image2b = data?.first_view_sp_2?.childImageSharp?.gatsbyImageData
  // const image3c = data?.first_view_sp_3?.childImageSharp?.gatsbyImageData
  // const imageSpArray = [image2b, image1a, image3c]

  const settings = {
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <>
      <Box sx={{ display: ["none", "block"] }}>
        <Slider {...settings}>
          {imageArray.map((c) => {
            return (
              <FullImage>
                <GatsbyImage
                  image={c}
                  style={{ height: "100vh", width: "100vw" }}
                />
              </FullImage>
            );
          })}
          <FullImage>
            <img src="/first-view-shibuya.jpg" style={{ height: "100vh" }} />
          </FullImage>
        </Slider>
      </Box>
      <Box sx={{ display: ["block", "none"] }}>
        <Slider {...settings}>
          {imageSpArray.map((c) => {
            return (
              <FullImage>
                <GatsbyImage
                  image={c}
                  style={{ height: "100vh", width: "100vw" }}
                />
              </FullImage>
            );
          })}
        </Slider>
      </Box>
    </>
  );
};

export default OfficeShibuyaSliderImage;
